<template>
  <div class="home">
<!--    <el-menu></el-menu>-->
    <img src="../../../../public/images/hardware/head.jpg" width="100%" height="100%">
    <div class="solarEnergy_box">
      <div class="solarEnergy_box_1">
        <img src="../../../../public/images/IPC/lowPowerConsumption/3.jpg" width="400" height="400">
      </div>
      <div class="solarEnergy_box_2">
        <h1>微型电池摄像机</h1>
        <p>品牌：维拍</p>
        <p>产品功能：超低功耗 无光夜视 自带WIFI AP</p>
        <p>内存容量：8G 16G 32G</p>
        <p>智能类型：安卓 IOS</p>
        <p>质保年限：1年</p>
        <p>颜色：</p>
      </div>
    </div>
    <div class="center">
      <div class="tile">详情介绍</div>
      <img src="../../../../public/images/IPC/littlecell/download.jpg">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import foot from '../../../../src/components/foot'
import elMenu from "../../../components/elMenu";
import navigation from "../../../components/navigation";

export default {
  name: "littlecell",
  components: {
    elMenu, foot,navigation
  },
}
</script>

<style scoped>
.solarEnergy_box{
  width: 1280px;
  margin: 10px auto;
  height: 430px;
  border: 1px #ececec solid;
}
.solarEnergy_box_1{
  width: 45%;
  float: left;
  height: 400px;
  text-align: center!important;
}
.solarEnergy_box_2{
  width: 51%;
  float: left;
  height: 400px;
  border-left: 1px solid #ececec;
  padding-left: 25px;
  margin-top: 15px;
}
.center{
  text-align: center;
  margin: auto;
}
.tile{
  font-size: 24px;
  margin: 70px;
}
</style>